import { useEffect, useState } from 'react';
import {
  User,
  UserManager,
  UserManagerSettings,
  WebStorageStateStore,
} from 'oidc-client';
import notificationManager from './NotificationManager';
import { jwtDecode } from 'jwt-decode';

const settings: UserManagerSettings = {
  authority: process.env.REACT_APP_OIDC_CLIENT_STS_AUTHORITY,
  client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
  redirect_uri: `${process.env.REACT_APP_OIDC_CLIENT_ROOT}/signin.html`,
  post_logout_redirect_uri: `${process.env.REACT_APP_OIDC_CLIENT_ROOT}/logout.html`,
  silent_redirect_uri: process.env.REACT_APP_API_SILENT_SIGNIN_REDIRECT,
  response_type: 'code',
  scope: 'email openid phone profile',
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};

const userManager = new UserManager(settings);
let sessionData: User | null;
userManager.getUser().then((user) => {
  sessionData = user;
});
let isMounted = false;

export const login = () => {
  // Store current url in localstorage such that we know
  // where to redirect the user to.
  window.localStorage.setItem('redirectUrl', window.location.href);
  return userManager.signinRedirect();
};

export const logout = () => {
  window.localStorage.setItem(
    'logoutredirectUrl',
    process.env.REACT_APP_OIDC_CLIENT_ROOT ?? '',
  );
  return userManager.signoutRedirect();
};

export const silentLogin = () => {
  let userData;

  window.localStorage.setItem('redirectUrl', window.location.href);
  if (!isMounted && sessionData) {
    isMounted = true;
    userData = userManager
      .signinSilent()
      .then((user) => {
        isMounted = false;
        sessionData = user;
      })
      .catch((error) => {
        isMounted = false;
        if (!error?.toString().toLowerCase().includes('network')) {
          notificationManager.error('Your session has been expired!');
          logout();
        }
      });
  }

  return userData;
};

export const useSession = () => {
  const [session, setSession] = useState<{ user: User }>();
  const [loading, setLoading] = useState<boolean>(true);

  // Load the user data
  useEffect(() => {
    userManager.getUser().then((newUser) => {
      if (newUser !== null) {
        setSession({
          user: newUser,
        });
      } else {
        setSession(undefined);
      }

      setLoading(false);
    });

    return () => {
      setSession(undefined); // This will clear the state when component is not mounted
    };
  }, [sessionData]);

  return {
    loading,
    session,
  };
};

interface DecodedToken {
  exp: number;
}

export const checkTokenExpiration = (token: string): boolean => {
  const decodedToken: DecodedToken = jwtDecode(token);
  const currentTime = Date.now() / 1000;

  if (decodedToken.exp < currentTime) {
    console.log('Token has expired');
    return false;
  }

  return true;
};
